@tailwind base;
@tailwind components;
@tailwind utilities;

/* Reset */
html {
    scroll-behavior: smooth;
}

body {
    max-width: 1440px;
    margin: auto;
}

button {
    outline: none !important;
}

/* Carousel */
.carousel .control-dots .dot {
    margin: 0 3px;
    background: #C4C4C4;
    box-shadow: none;
}

.carousel .control-dots .dot.selected,
.react-multi-carousel-dot-list .react-multi-carousel-dot--active button {
    width: 24px;
    border-radius: 5px;
}

.react-multi-carousel-dot-list .react-multi-carousel-dot--active button  {
    @apply bg-blue
}

.react-multi-carousel-dot button {
    border: none;
    background: #C4C4C4;
}

/* Background */
.bg-blue {
    background-color: #89CBFD;
}

.card:hover {
    background-color:  #89CBFD;
}

.bg-lightblue {
    background-color: #F2FAFF;
    @apply txt-blue w-max py-1 px-4 rounded-md;
}

.bg-lightblue-2 {
    background-color: #D3ECFE;
}

.bg-lightblue-3 {
    background-color: #EFF8FF;
}
.bg-lightblue-4 {
    background-color: #F7FBFF;;
}

.bg-lightorange {
    background-color: #FFEED6;
}

.bg-default {
    background-color: #FFFFFF;
}

.bg-pink {
    background-color: #F96BD4;
}

.bg-gray {
    background-color: #FAFAFA;
}

.bg-gray2 {
    background-color: #F5F5F5;
}
.bg-gray3 {
    background-color: #F2F2F2;
}

.bg-transparent {
    background: rgba(0, 0, 0, 0.1);
}

.bg-orange {
    background: #FFCA7A;
}

/* Text */
.txt-blue {
    color: #89CBFD;
}

.txt-orange {
    color: #FFCA7A;
}

.txt-black {
    color: #585858;
}

.txt-dark {
    color: #303030;
}

.txt-gray {
    color: #BDBDBD
}

/* Buttons */
.btn {
    border: none;
    outline: none;
}

.btn-blue {
    @apply px-4 py-2 bg-blue text-white rounded-xl w-max shadow-xl
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
    background: #4A4A4A;
    border: 13px solid transparent;
    background-clip: padding-box;
}

.custom-scroll::-webkit-scrollbar {
    width: 30px;
}

.custom-scroll::-webkit-scrollbar-thumb {
    border: 14px solid transparent;
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #4A4A4A;
}

.custom-scroll::-webkit-scrollbar-track {
    border: 14px solid transparent;
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #E7E7E7;
}

/* Border */
.border-gray {
    border: 1px solid #BDBDBD;
}

.border-gray-2 {
    border: 2px solid #BDBDBD;
}

.border-black {
    border: 1px solid #4A4A4A;
}

.border-blue {
    border: 2px solid #89CBFD;
}

.h-90percent {
    height: 90%;
}

.shadow-gray {
    box-shadow: 0px 9px 20px rgba(0, 0, 0, .05)
}

.shadow-gray-2 {
    box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, .05)
}


@media(max-width: 552px) {
    .one-column {
        grid-template-columns: 1fr;
    }
}

input[type=radio]:checked ~ .checkmark {
    color: white;
    background-color: #89CBFD;
}

@media print {
    .table {
        width: 100%;
        margin-top: 1rem;
    }
    .table, .th, .td {
        border: 1px solid black;
        border-collapse: collapse;
    }
    .th, .td {
        padding: 15px;
    }
    .name {
        margin-bottom: 1rem;
        display: block;
    }
    .page-break {
        page-break-after: always;
        display: block;
        margin-bottom: 1rem;
    }
}